.App {
  text-align: center;
}

/*font-family: 'Knewave', cursive;*/
/*font-family: 'Barlow', sans-serif;*/
/*font-family: 'Barlow Condensed', sans-serif;*/
/*font-family: 'Bebas Neue', cursive;*/


@font-face {
  font-family: 'bebasregular';
  src: url('bebas-webfont.woff2') format('woff2'),
  url('bebas-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'knewaveregular';
  src: url('knewave-regular-webfont.woff2') format('woff2'),
  url('knewave-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'barlowregular';
  src: url('barlow-regular-webfont.woff2') format('woff2'),
  url('barlow-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'barlow_condensedregular';
  src: url('barlowcondensed-regular-webfont.woff2') format('woff2'),
  url('barlowcondensed-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}



// Fonts
$knewave: 'Knewave', 'knewaveregular';
$barlow: 'Barlow', 'barlowregular';
$barlow-condensed: 'Barlow Condensed', 'barlow_condensedregular';
$bebas-neue: 'bebasregular';




// Colors
$dark-olive: #4C4E2C;
$military-olive: #7C7644;
$olive-green: #9CA65C;
$rotten-olive: #B4B294;
$sour-olive: #ECEEB4;
$ripe-olive: #2C2B2B;
$sunlight-olive: #FFF9E8;
$box-shadow:  -5px 5px $military-olive;

#root {
  min-width: 375px;
  background: $sour-olive;

}

.container-fluid {
  max-width: 1140px;
  background: url('./oliva-plant.svg');
  background-size: contain;
  background-repeat: no-repeat;
  overflow: visible;
  p, li {
    font-size: 1.1rem;
    color: $military-olive;
    font-family: $barlow;
  }
}

h1 {
  font-family: $bebas-neue;
  color: $military-olive;
  font-weight: 900;
  //font-size: 1;/

  span {
    color: $dark-olive;
  }
}

h2 {
  color: $olive-green;
  font-family: $barlow-condensed;
  font-weight: 500;
  line-height: 38px;
  text-transform: uppercase;
  font-weight: 700;
  span {
    font-size: 1.5rem;
  }
}

h3 {
  font-family: $bebas-neue;
  color: $military-olive;
}
hr.line-header {
  height: 3px;
  background: $military-olive;
  width: 50%;
  margin-left: 0;
}

hr.paragraph {
  background: $sunlight-olive;
  box-shadow: $box-shadow;
  height: 10px;
  width: 50%;
  margin-left: 0;
}

hr.paragraph-center {
 display: none;
}

.oliva-button {

  height: 61px;
  width: 182px;
  background: $military-olive;
  border-radius: 8px;
  box-shadow: -5px 5px $dark-olive;
  //padding: 9px;
  //overflow: hidden;
  display: grid;
  border: none;
  cursor: pointer;
  outline: none;
  font-family: $bebas-neue;
  color: $sour-olive;
  font-size: 2rem;
  padding: 0;
  margin: 0;

  p {
    font-family: $bebas-neue;
    color: $sour-olive;
    font-size: 2rem;
    border-radius: 8px ;
    margin: auto;

  }


  &:hover {
    background: $sunlight-olive;
    text-decoration: none;
    color: $military-olive;


    p {
      color: $military-olive;
    }
  }

  &:active {
    transform:  translateX(-3px) translateY(3px);
    box-shadow: -2px 2px $ripe-olive, 5px -5px $sour-olive;
    outline: none;

  }
}


.box-callout {

  background: $sunlight-olive;
  border-radius: 8px;
  box-shadow:  -5px 5px $military-olive;
  display: table;
  padding: 16px;
  width: calc(100% - 5px);
  margin-left: 5px;
  .content {
    color: $military-olive;
    font-family: $bebas-neue;
    height: 100%;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    font-size: 3vmin;
    text-align: center;
    display: flex;
    letter-spacing: 3px;
    margin: 0;
  };

}

p.call-to-action {
  font-family: $knewave;
  color: $dark-olive;
  font-size: 2rem;
  text-align: center;
  text-transform: uppercase;

}

li {
  margin-bottom: 0.5rem;
}

.short-block {
  width: 100%;

  .content {
    horiz-align: center;

    img {
      display: block;
      margin: auto;
    }
  p {

    color: $military-olive;
    font-size: 2rem;
    font-family: $knewave;
    text-align: center;
    }
  }
}

.button-row {
  display: table;
  height: 100%;
}

.button-column {
  display: table-cell;
  horiz-align: center;
  vertical-align: bottom;
}

.paragraph-column {
  display: table;
  height: 100%;
  .paragraph-center {
    display: table-cell;
    vertical-align: middle;
  }
}

.form-control {
  height: 100%;
}

.top-row {
  //height: 100vh;

}

.background-img {
  width: 100%;
  position: absolute;
}

.logo-col {
  //height:18vh;
  //max-height: 200px;
  .logo {
    //display: block;
    //height: 200px;

  }

}

.height-adjust {
  height: 100%
}

.works-content {
  position: absolute;
  top: 0;
}

@media only screen and (min-width:1200px) {
  .container-fluid {
    .top-row {
      //max-height: calc(100vh - 200px);
    }
  }
}

@media only screen and (max-width: 991px) {
  .works-content {
    //top: -10%;
  }
}

@media only screen and (max-width: 768px) {
  .works-content {
    position: static;
  }
  ol {
    padding-left: 20px;
  }
}


@media only screen and (max-width: 767px) {
  .container-fluid {
    background-image: none;
  }

  .box-callout {
    .content {
      font-size: 3vmin;
    }


  }



.logo-col {
  display: block;
  margin: 0 auto ;
  .logo {
    display: block;
    margin: 0 auto ;
  }
}

  hr.paragraph-center {
    background: $sunlight-olive;
    box-shadow: $box-shadow;
    height: 10px;
    width: 50%;
    display: block;

  }

}

.signup-row {

  .oliva-button {
    margin: 0 auto;

  }
}


